import React, {PureComponent} from 'react'
import classnames from 'classnames'
import Customizer from '../components/@vuexy/customizer/Customizer'
import Sidebar from './components/menu/horizontal-menu/HorizontalMenu'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import themeConfig from '../configs/themeConfig'
import {connect} from 'react-redux'
import {changeNavbarColor, changeNavbarType, changeFooterType, changeMenuColor, hideScrollToTop, changeMode} from '../redux/actions/customizer/index'
let error_log = require('../error_log')
class HorizontalLayout extends PureComponent {
	state = {
		width: window.innerWidth,
		sidebarState: false,
		layout: this.props.app.customizer.theme,
		collapsedContent: false,
		sidebarHidden: false,
		currentLang: 'en',
		appOverlay: false,
		customizer: false,
		currRoute: this.props.location.pathname,
		menuOpen: themeConfig.menuOpen,
		version: global.commonvariable.getcommon.variable.version,
		usercode: 0,
	}

	mounted = false

	updateWidth = () => {
		try {
			if (this.mounted) {
				this.setState(prevState => ({
					width: window.innerWidth,
				}))
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'updateWidth', 0, error.toString())
		}
	}

	updateScroll = () => {
		try {
			if (this.mounted) {
				this.setState({scroll: window.pageYOffset})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'updateScroll', 0, error.toString())
		}
	}

	handleCustomizer = bool => {
		try {
			this.setState({
				customizer: bool,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleCustomizer', 0, error.toString())
		}
	}

	componentDidMount() {
		try {
			this.mounted = true
			if (this.mounted) {
				if (window !== 'undefined') {
					window.addEventListener('resize', this.updateWidth, false)
					window.addEventListener('scroll', this.updateScroll, false)
				}
				if (this.props.location.pathname === '/pages/profile') {
					this.setState({
						sidebarState: true,
						collapsedContent: true,
					})
				}
				let layout = this.props.app.customizer.theme
				return layout === 'dark'
					? document.body.classList.add('dark-layout')
					: layout === 'semi-dark'
						? document.body.classList.add('semi-dark-layout')
						: null
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'componentDidMount', 0, error.toString())
		}
	}

	componentDidUpdate() {
		try {
			if (this.mounted) {
				if (this.state.currRoute !== this.props.location.pathname) {
					this.handleRouteChange()
					this.setState({
						currRoute: this.props.location.pathname,
					})
				}

				let layout = this.props.app.customizer.theme
				if (layout === 'dark') {
					document.body.classList.remove('semi-dark-layout')
					document.body.classList.add('dark-layout')
				} else if (layout === 'semi-dark') {
					document.body.classList.remove('dark-layout')
					document.body.classList.add('semi-dark-layout')
				} else {
					return document.body.classList.remove('dark-layout', 'semi-dark-layout')
				}
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'componentDidUpdate', 0, error.toString())
		}
	}

	handleRouteChange = () => {
		try {
			if (this.props.location.pathname === '/pages/profile') {
				this.setState({
					collapsedContent: true,
				})
			} else {
				this.setState({
					sidebarState: false,
					collapsedContent: false,
				})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleRouteChange', 0, error.toString())
		}
	}

	toggleSidebarMenu = () => {
		try {
			this.setState({
				sidebarState: !this.state.sidebarState,
				collapsedContent: !this.state.collapsedContent,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'toggleSidebarMenu', 0, error.toString())
		}
	}

	sidebarMenuHover = () => {
		try {
			this.setState({
				sidebarState: !this.state.sidebarState,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'sidebarMenuHover', 0, error.toString())
		}
	}

	handleSidebarVisibility = () => {
		try {
			if (this.mounted) {
				if (window !== undefined) {
					window.addEventListener('resize', () => {
						if (this.state.sidebarHidden) {
							this.setState({
								sidebarHidden: !this.state.sidebarHidden,
							})
						}
					})
				}
				this.setState({
					sidebarHidden: !this.state.sidebarHidden,
				})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleSidebarVisibility', 0, error.toString())
		}
	}

	handleCurrentLanguage = lang => {
		try {
			this.setState({
				currentLang: lang,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleCurrentLanguage', 0, error.toString())
		}
	}

	handleAppOverlay = value => {
		try {
			if (value.length > 0)
				this.setState({
					appOverlay: true,
				})
			else if (value.length > 0 || value === '') {
				this.setState({
					appOverlay: false,
				})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleAppOverlay', 0, error.toString())
		}
	}

	handleAppOverlayClick = () => {
		try {
			this.setState({
				appOverlay: false,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'handleAppOverlayClick', 0, error.toString())
		}
	}

	componentWillUnmount() {
		try {
			this.mounted = false
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'HorizontalLayout', 'componentWillUnmount', 0, error.toString())
		}
	}

	render() {
		let customizerProps = this.props.app.customizer
		let navbarTypeArr = ['sticky', 'static', 'sticky', 'floating', 'hidden']
		let menuThemeArr = ['primary', 'success', 'danger', 'info', 'warning', 'dark', 'mycolor']
		return (
			<div
				className={classnames(`wrapper horizontal-layout theme-${customizerProps.menuTheme}`, {
					'menu-collapsed': this.state.collapsedContent === true && this.state.width > 1200,
					'fixed-footer': customizerProps.footerType === 'sticky',
					'navbar-static': customizerProps.navbarType === 'static',
					'navbar-sticky': customizerProps.navbarType === 'sticky',
					'navbar-floating': customizerProps.navbarType === 'floating' || !navbarTypeArr.includes(customizerProps.navbarType),
					'navbar-hidden': customizerProps.navbarType === 'hidden',
					'theme-primary': !menuThemeArr.includes(customizerProps.menuTheme),
				})}
			>
				<Sidebar
					toggleSidebarMenu={this.toggleSidebarMenu}
					sidebarState={this.state.sidebarState}
					sidebarHover={this.sidebarMenuHover}
					sidebarVisibility={this.handleSidebarVisibility}
					visibilityState={this.state.sidebarHidden}
					activePath={this.props.match.path}
					currentLang={this.state.currentLang}
					activeTheme={customizerProps.menuTheme}
					collapsed={this.state.collapsedContent}
					menuOpen={this.state.menuOpen}
					navbarType={customizerProps.navbarType}
				/>
				<div
					className={classnames('app-content content', {
						'show-overlay': this.state.appOverlay === true,
					})}
					onClick={this.handleAppOverlayClick}
				>
					<Navbar
						horizontal
						scrolling={this.state.scroll > 50 ? true : false}
						toggleSidebarMenu={this.toggleSidebarMenu}
						sidebarState={this.state.sidebarState}
						sidebarVisibility={this.handleSidebarVisibility}
						currentLang={this.state.currentLang}
						changeCurrentLang={this.handleCurrentLanguage}
						handleAppOverlay={this.handleAppOverlay}
						appOverlayState={this.state.appOverlay}
						navbarColor={customizerProps.navbarColor}
						navbarType={customizerProps.navbarType}
					/>
					<div className='content-wrapper'>{this.props.children}</div>
				</div>

				<Footer footerType={customizerProps.footerType} hideScrollToTop={customizerProps.hideScrollToTop} />
				{customizerProps.disableCustomizer !== true ? (
					<Customizer
						scrollToTop={customizerProps.hideScrollToTop}
						activeNavbar={customizerProps.navbarColor}
						activeMode={customizerProps.theme}
						navbarType={customizerProps.navbarType}
						footerType={customizerProps.footerType}
						menuTheme={customizerProps.menuTheme}
						customizerState={this.state.customizer}
						handleCustomizer={this.handleCustomizer}
						changeNavbar={this.props.changeNavbarColor}
						changeNavbarType={this.props.changeNavbarType}
						changeFooterType={this.props.changeFooterType}
						changeMenuTheme={this.props.changeMenuColor}
						hideScrollToTop={this.props.hideScrollToTop}
						changeMode={this.props.changeMode}
					/>
				) : null}
				<div className='sidenav-overlay' onClick={this.handleSidebarVisibility} />
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		app: state.customizer,
	}
}
export default connect(mapStateToProps, {
	changeNavbarColor,
	changeNavbarType,
	changeFooterType,
	changeMenuColor,
	hideScrollToTop,
	changeMode,
})(HorizontalLayout)
