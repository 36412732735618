import mock from '../mock'
const data = [
	{
		name: 'Raju',
		city: 'Madurai',
		district: 'Madurai',
		qualification: 'B.E',
		experience: '10 months',
		logo: require('../../assets/img/portrait/small/avatar-s-9.jpg'),
	},
	{
		name: 'Ram',
		city: 'Madurai',
		district: 'Madurai',
		qualification: 'B.SC',
		experience: '2 years',
		logo: require('../../assets/img/portrait/small/avatar-s-1.jpg'),
	},
	{
		name: 'Chitra',
		city: 'Virudhunagar',
		district: 'Virudhunagar',
		qualification: 'B.Tech',
		experience: '5 years',
		logo: require('../../assets/img/portrait/small/avatar-s-2.jpg'),
	},
	{
		name: 'Abirami',
		city: 'Rajapalayam',
		district: 'Virudhunagar',
		qualification: 'Ph.D',
		experience: '10 years',
		logo: require('../../assets/img/portrait/small/avatar-s-4.jpg'),
	},
	{
		name: 'Lakshmi',
		city: 'Sattur',
		district: 'Virudhunagar',
		qualification: 'B.Tech',
		experience: '8 months',
		logo: require('../../assets/img/portrait/small/avatar-s-6.jpg'),
	},
]
mock.onGet('/api/wishlist_data/data').reply(200, {
	data,
})
