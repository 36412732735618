import mock from '../mock'
const data = [
	{
		governmentorganization: 'Tamilnadu Electricity Board',
		jobtitle: 'Junior Engineer',
		counter: '3',
		status: 'Inactive',
		logo: require('../../assets/img/portrait/small/tamilnadu.png'),
	},
	{
		governmentorganization: 'Delhi Metro RaiL Corporation',
		jobtitle: 'General Manager',
		counter: '3',
		status: 'Active',
		logo: require('../../assets/img/portrait/small/railway.png'),
	},
	{
		governmentorganization: 'Airports authority of India',
		jobtitle: 'Accounts Manager',
		counter: '3',
		status: 'Inactive',
		logo: require('../../assets/img/portrait/small/airport.jpg'),
	},
]
mock.onGet('/api/governmentjobs/data').reply(200, {
	data,
})
