import mock from '../mock'
const data = [
	{
		id: 1,
		jobfunction: 'Developer',
		jobrole: 'Game Developer',
		no_of_skills: '18',
		counter: '3',
		status: 'Inactive',
	},
	{
		id: 2,
		jobfunction: 'Developer',
		jobrole: 'PHP Developer',
		no_of_skills: '20',
		counter: '3',
		status: 'Active',
	},
	{
		id: 3,
		jobfunction: 'Developer',
		jobrole: 'Android Developer',
		no_of_skills: '7',
		counter: '3',
		status: 'Inactive',
	},
	{
		id: 4,
		jobfunction: 'Teacher',
		jobrole: 'Sports Teacher',
		no_of_skills: '10',
		counter: '3',
		status: 'Inactive',
	},
	{
		id: 5,
		jobfunction: 'Professor',
		jobrole: 'Maths Professor',
		no_of_skills: '2',
		counter: '3',
		status: 'Active',
	},
	{
		id: 6,
		jobfunction: 'Driver',
		jobrole: 'Car Driver',
		no_of_skills: '32',
		counter: '3',
		status: 'Inactive',
	},
	{
		id: 7,
		jobfunction: 'Developer',
		jobrole: 'Web Developer',
		no_of_skills: '8',
		counter: '3',
		status: 'Active',
	},
]
mock.onGet('/api/jobrole/data').reply(200, {
	data,
})
