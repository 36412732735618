import mock from '../mock'
const data = [
	{
		sno: '1',
		employerid: '1',
		employeer: 'Shiva Software Solutions ,Virudhunagar',
		companyinfo: 'IT Service | Private | Company',
		varcompany_profileimg: require('../../assets/img/portrait/small/sss_logo.png'),
		varactivitytype: 'Manufacture',
		varindustry_category: 'IT Services',
		varcompany_name: 'Shiva Software Solutions',
		varcompany_mailid: 'shiva@shivasoftwares.com',
		varcompany_type: 'Private',
		varemployer_type: 'Company',
		contactinfo: 'Shanthi, HR Manager',
		contactphoneno: '9787226655',
		contactlandlineno: '0456-420420',
		contactmailid: 'hr@shivasoftwares.com',
		industrysector: 'IT SERVICES',
		regon: '22/07/2020',
		subscribedon: '22/07/2020',
		lastloginon: '3 days ago',
		source: 'Google',
		status: 'Pending',
		action: 'Eye',
		varregistered_on: ' 11/07/2020',
		varapproved_on: '15/07/2020',
		varereferedby: 'NewsPaper-Dinakaran ',
		statuscode: 2,
		varcontact_name: 'Shanthi V',
		varcontact_post: 'HR Manager',
		varcontact_address: '#259,Teppam South Bazar ',
		varcontact_city: 'Virudhunagar -626001, Tamilnadu',
		varcontact_state: 'Tamilnadu',

		varcontact_phoneno: '+91 9715148697',
		varcontact_mailid: 'hr@shivasoftwares.com',
		varcontact_Website: 'www.shivasoftwares.com',
		varcontact_landline: '+0456-420420',
	},
	{
		sno: '2',
		employerid: '2',
		employeer: 'Shiva System ,Virudhunagar',
		companyinfo: 'IT Service | Private | Company',
		varcompany_profileimg: require('../../assets/img/portrait/small/sss_logo.png'),
		varactivitytype: 'Manufacture',
		varindustry_category: 'IT Services',
		varcompany_name: 'Shiva System ,Virudhunagar',
		varcompany_mailid: 'shiva@shivasoftwares.com',
		varcompany_type: 'Private',
		varemployer_type: 'Company',
		contactinfo: 'Shanthi, HR Manager',
		contactphoneno: '9787226655',
		contactlandlineno: '0456-420420',
		contactmailid: 'hr@shivasoftwares.com',
		industrysector: 'IT SERVICES',
		regon: '22/07/2020',
		subscribedon: '22/07/2020',
		lastloginon: '3 days ago',
		source: 'Google',
		status: 'Pending',
		action: 'Eye',
		varregistered_on: ' 12/07/2020',
		varapproved_on: '15/07/2020',
		varereferedby: 'NewsPaper-Dinakaran ',
		statuscode: 2,
		varcontact_name: 'Shanthi V',
		varcontact_post: 'HR Manager',
		varcontact_address: '#259,Teppam South Bazar ',
		varcontact_city: 'Virudhunagar -626001, Tamilnadu',
		varcontact_state: 'Tamilnadu',

		varcontact_phoneno: '+91 9715148697',
		varcontact_mailid: 'hr@shivasoftwares.com',
		varcontact_Website: 'www.shivasoftwares.com',
		varcontact_landline: '+0456-420420',
	},
	{
		sno: '3',
		employerid: '3',
		employeer: 'Shiva System ,Virudhunagar',
		companyinfo: 'IT Service | Private | Company',
		varcompany_profileimg: require('../../assets/img/portrait/small/sss_logo.png'),
		varactivitytype: 'Manufacture',
		varindustry_category: 'IT Services',
		varcompany_name: 'VCCC System ,Virudhunagar',
		varcompany_mailid: 'vccc@shivasoftwares.com',
		varcompany_type: 'Private',
		varemployer_type: 'Company',
		contactinfo: 'Shanthi, HR Manager',
		contactphoneno: '9787226655',
		contactlandlineno: '0456-420420',
		contactmailid: 'hr@shivasoftwares.com',
		industrysector: 'IT SERVICES',
		regon: '22/07/2020',
		subscribedon: '22/07/2020',
		lastloginon: '3 days ago',
		source: 'Google',
		status: 'Pending',
		action: 'Eye',
		varregistered_on: ' 21/07/2020',
		varapproved_on: '15/07/2020',
		varereferedby: 'NewsPaper-Dinakaran ',
		statuscode: 2,
		varcontact_name: 'Shanthi V',
		varcontact_post: 'HR Manager',
		varcontact_address: '#259,Teppam South Bazar ',
		varcontact_city: 'Virudhunagar -626001, Tamilnadu',
		varcontact_state: 'Tamilnadu',

		varcontact_phoneno: '+91 9715148697',
		varcontact_mailid: 'hr@shivasoftwares.com',
		varcontact_Website: 'www.shivasoftwares.com',
		varcontact_landline: '+0456-420420',
	},
	{
		sno: '4',
		employerid: '4',
		employeer: 'Shiva System ,Virudhunagar',
		companyinfo: 'IT Service | Private | Company',
		varcompany_profileimg: require('../../assets/img/portrait/small/sss_logo.png'),
		varactivitytype: 'Manufacture',
		varindustry_category: 'IT Services',
		varcompany_name: 'TKK System ,Virudhunagar',
		varcompany_mailid: 'tcc@shivasoftwares.com',
		varcompany_type: 'Private',
		varemployer_type: 'Company',
		contactinfo: 'Shanthi, HR Manager',
		contactphoneno: '9787226655',
		contactlandlineno: '0456-420420',
		contactmailid: 'hr@shivasoftwares.com',
		industrysector: 'IT SERVICES',
		regon: '22/07/2020',
		subscribedon: '22/07/2020',
		lastloginon: '3 days ago',
		source: 'Google',
		status: 'Pending',
		action: 'Eye',
		varregistered_on: ' 01/07/2020',
		varapproved_on: '15/07/2020',
		varereferedby: 'NewsPaper-Dinakaran ',
		statuscode: 2,
		varcontact_name: 'Shanthi V',
		varcontact_post: 'HR Manager',
		varcontact_address: '#259,Teppam South Bazar ',
		varcontact_city: 'Virudhunagar -626001, Tamilnadu',
		varcontact_state: 'Tamilnadu',

		varcontact_phoneno: '+91 9715148697',
		varcontact_mailid: 'hr@shivasoftwares.com',
		varcontact_Website: 'www.shivasoftwares.com',
		varcontact_landline: '+0456-420420',
	},
]
mock.onGet('/api/employer_approval/data').reply(200, {
	data,
})
