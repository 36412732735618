export default global.errormessage = {
	getmessages: {
		messages: {
			delete_bogus_message: 'To confirm deletion, type Delete in the text input field.',
			revoke_bogus_message: 'To confirm revoke, type Revoke in the text input field.',
			err_valid: 'Please enter valid details',
			errimagetype: 'Please upload svg formats only',
			err_language: 'Please Choose English Language',
			errlanguage: 'Please select the language',
			errstate: 'Please select the state',
			errimage: 'Please Upload Image',
			errdistrict: 'Please select the district',
			errordis: 'Please enter district',
			erreducationcatgory: 'Please select the educational category',
			errdpjobfunction: 'Please select the job function',
			errdpskills: 'Please select the skills',
			errtxteducationcategory: 'Please select the educationcategory',
			//designation
			err_designation: 'Please enter designation',
			//splash_screen
			errcontent: 'Please enter content or upload image',
			//Specialization
			errtxtcategory: 'Please select the educational category',
			errtxtspecialization: 'Please enter specialization',
			//Facility
			errFacility: 'Please enter facility',
			//Government Organization
			errorganization_type: 'Please select the government type',
			errgovernment_organization: 'Please enter Government organization name',
			imageerror_url: 'Please upload job Government organozoation icon',
			// //user role
			err_userrole: 'Please enter user role',
			//Government Organization
			errgntype: 'Please select the government type',
			//user
			errnameofuser: 'Please enter name of the user',
			errdesignation: 'Please select the designation',
			errusername: 'Please enter username',
			errpassword: 'Please enter password',
			erruserrole: 'Please select the userrole',
			errnewpassword: 'Please enter new password',
			//validation for password length
			err_newpassword: 'Please enter atleast 6 characters password',
			errdatatable: 'Please add Taluk',
			errconfirmpassword: 'Please enter confirm password',
			//validation for password length
			err_confirmpassword: 'Confirm password does not match',
			err_reset: 'Reset password successfully',
			//Login
			erremail: 'Please enter username',
			erroldpassword: 'Please enter old password ',
			erroldpasswordlength: 'Please enter atleast 6 characters password',
			//City
			errcity: 'Please enter Taluk ',
			//State
			errtxtstate: 'Please enter state ',
			//Industry Sector
			errindustry_type: 'Please enter industry / sector',
			errindustry_icon: 'Please upload industry / sector icon',
			//Education Qualiifcation
			errtxtqualification: 'Please enter qualification',
			//Map by qualification
			//Map by qualification
			errqualification: 'Please Select the qualification',
			errspecialization: 'Please choose the specialization',
			//Map by Specialization
			err_qualification: 'Please Choose the qualification',
			err_specialization: 'Please select the specialization',
			//Job Function
			errjob_function: 'Please enter job function',
			err_job_icon: 'Please upload job function icon',
			//Job Role
			errjobrole: 'Please enter job role',
			//Skill
			errdpjobrole: 'Please select the job role',
			errtxtskill: 'Please enter skill',
			//Job Package
			errtxt_package: 'Please enter job package name',
			errtxt_packagevalidity: 'Please enter validity for the job package',
			errtxt_validityperjob: 'Please enter Maximum validity per job post',
			errtxt_price: 'Please enter price',
			errtxt_noofposts: 'Please enter no. of jobs allowed for the package',
			errtxt_jobvacancies: 'Please enter no. of vacancies allowed for the job post',
			errtxt_profileview: 'Please enter no. of profiles to be viewed by the employer',
			errtxt_profileshortlist: 'Please enter no. of profiles to be shortlisted per job post ',
			errtxt_candidateallowed: 'Please enter no. of employees to be applied for the job post',
			errtxt_candidateinvited: 'Please enter no. of employeed to be invited per job post',
			errselect_plan: 'Please select the plan type',
			errselect_user: 'Please select the employer ',
			//SMS TEMPLATE
			errtemplate_name: 'Please enter template name',
			errtemplate_message: 'Please enter tempate message',
			//Send SMS,SMS Template
			errsmstype: 'Please select the SMS type',
			//Send SMS
			errRecipientCriteria: 'Please select the recipient criteria',
			errsmsmessage: 'Please enter SMS message',
			errmobilenorecipients: 'Please enter mobile no. of the recipients',
			//News And Event
			errcategory_type: 'Please select the category',
			errtype: 'Please select the type',
			errnewstitle: 'Please enter title',
			errexpirydate: 'Expiry date is required',
			erreventvenue: 'Please enter venue',
			errstartenddate: 'Start and End date is required',
			erreventdescription: 'Please enter description',
			errfile: 'Please upload the file',
			//government jobs
			errjobtitle: 'Please enter Job Title',
			errnoofvacancies: 'Please enter no of vacancies',

			//Personal Info
			err_emp_fullname: 'Please enter full name',
			err_emp_fathername: 'Please enter father name',
			err_emp_spousename: 'Please enter Spouse name',
			err_emp_medicalhistory: 'Please enter Medicalhistory',
			err_empdob: 'Please enter DOB',
			err_empresume: 'Please upload resume',
			err_dp_gender: 'Please enter Gender',
			err_dp_maritalstatus: 'Please enter marital status',
			err_txtaadharno: 'Please enter valid Udyam/Udyog Aadhar number',
			err_txtaadharno1: 'Udyam/Udyog Aadhar No. already exists',
			err_txtusername: 'Please enter username',
			err_no_lanaguage: 'Please enter Lanaguage Details',
			errimage_type: 'Please upload jpeg,jpg,pdf,png formats only',
			//Contact Info
			errtxt_doorno_street: 'Please enter doorno',
			errtxt_email: 'Please enter email',
			errtxt_emailvalid: 'Please enter valid email',
			errtxt_area: 'Please enter area name',
			errtxt_mobileno1: 'Please enter mobile number',
			err_txt_mobileno1: 'Please enter mustbe 10 digits',
			errtxt_pincode: 'Please enter valid pincode',
			//schooling
			errinstitution: 'Please enter institution',
			errlocation: 'please enter location',
			errpercentage: 'please enter percentage',
			err_yearofpassing: 'please enter year of passing',

			//reference
			errtxt_refname: 'Please enter name',
			errtxt_designation: 'Please enter designation',
			errtxt_organization: 'Please enter organization',
			errdp_relationship: 'Please select the relationship to candidate',
			errtxt_refemail: 'Please enter email',
			errtxt_organization: 'Please enter organization',
			errtxt_refmobileno: 'Please enter mobile number',
			errtxt_remarks: 'Please enter remarks',
			errtxt_explocation: 'Please enter location',

			errmonth: 'please enter month',
			err_month: 'please enter valid month',
			erryear: 'please enter year',
			err_year: 'please enter valid year',

			//preference
			errdp_employmenttype: 'please select the employmenttype',
			errdp_prefjoblocation: 'please select the joblocation',
			errtimeforjoining: 'please enter joining time',
			errexpectedsalary: 'please enter salary',
			errvalidsalary: 'please enter valid salary',
			erroverallmonyear: 'Please enter valid date',
			errinvalidyear: 'Please enter valid experience year',

			err_invalidRemarks: 'Please enter remarks',
			erremailid: 'Please enter email',
			errjoining: 'please enter inactive days',
		},
		// rest of your translation object
	},
	// other global config variables you wish
}
