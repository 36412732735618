import mock from '../mock'
const data = [
	{
		skill: 'Android Studio',
		jobfunction: 'Software Developer',
		jobrole: 'Android Developer',
		status: 'Active',
		languagecount: '3',
		action: 'Edit',
	},
	{
		skill: 'Laravel',
		jobfunction: 'PHP Developer',
		jobrole: 'PHP',
		status: 'Inactive',
		languagecount: '0',
		action: 'Delete',
	},
	{
		skill: 'React',
		jobfunction: 'PHP Developer',
		jobrole: '',
		languagecount: '4',
		status: 'Active',
		action: 'Edit',
	},
]
mock.onGet('/api/skill/data').reply(200, {
	data,
})
