import mock from '../mock'
const data = [
	{
		jobgroup: 'Teacher',
		gender: 'Female',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Inactive',
		action: 'Edit',
	},
	{
		jobgroup: 'Developer',
		gender: 'Female',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Active',
		action: 'Delete',
	},
	{
		jobgroup: 'Manager',
		gender: 'Male',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Inactive',
		action: 'Edit',
	},
	{
		jobgroup: 'Head Master',
		gender: 'Male',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Active',
		action: 'Delete',
	},
	{
		jobgroup: 'Officer',
		gender: 'Male',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Active',
		action: 'Delete',
	},
	{
		jobgroup: 'Nurse',
		gender: 'Female',
		maritalstatus: 'Single',
		counter: '3',
		status: 'Inactive',
		action: 'Edit',
	},
]
mock.onGet('/api/jobgroup/data').reply(200, {
	data,
})
