//Created by:Varsha
//Date : 03/07/2020

import mock from '../mock'
const data = [
	{
		id: 1,
		template_name: 'New Package Available',
		template_message: 'There is New Package Available.Visit Jobanya!',
		SMS_count: 2,
	},
	{
		id: 2,
		template_name: 'Warning',
		template_message:
			'You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!!!Hurry Up!!! Grab the offer !!!!Special Offer for you!!!!You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya! ',
		SMS_count: 6,
	},
	{
		id: 3,
		template_name: 'Login Message',
		template_message:
			'You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!!!Hurry Up!!! Grab the offer !!!!Special Offer for you!!!!You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!!!Hurry Up!!! Grab the offer !!!!Special Offer for you!!!!You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!!!Hurry Up!!! Grab the offer !!!!Special Offer for you!!!!You are inactive for last three months.Please Visit Jobanya! There are new package available! Enjoy with purchasing new package!offer valid till this tuesday!You are inactive for last three months.Please Visit Jobanya! You are inactive for last three months.Please Visit Jobanya!  ',
		SMS_count: 3,
	},
	{
		id: 4,
		template_name: 'Success Message',
		template_message: 'Thank You for being active User',
		SMS_count: 2,
	},
	{
		id: 5,
		template_name: 'ThankYou Message',
		template_message: 'Thank You for visiting our site!',
		SMS_count: 3,
	},
	{
		id: 6,
		template_name: 'Logout Message',
		template_message: 'You are Successfully logged out!',
		SMS_count: 3,
	},
	{
		id: 7,
		template_name: 'Update Message',
		template_message: 'New Update Available',
		SMS_count: 2,
	},
]
mock.onGet('/api/sms_template/data').reply(200, {
	data,
})
