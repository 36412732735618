import React from 'react'
import ScrollToTop from 'react-scroll-up'
import {Button} from 'reactstrap'
import {ArrowUp} from 'react-feather'
import classnames from 'classnames'

const Footer = props => {
	let footerTypeArr = ['sticky', 'static', 'hidden']
	return (
		<footer
			className={classnames('footer footer-light', {
				'footer-static': props.footerType === 'static' || !footerTypeArr.includes(props.footerType),
				'd-none': props.footerType === 'hidden',
			})}
		>
			<p className='mb-0 clearfix'>
				<span className='float-md-left d-block d-md-inline-block mt-25'>
					Copyright © {new Date().getFullYear()}
					<a href='https://thebestjobs.in/' target='_blank' rel='noopener noreferrer'>
						JOBANYA,
					</a>
					All rights reserved
				</span>
				<span className='float-md-right d-none d-md-block'>
					<span className='align-middle'>Powered by</span>{' '}
					<a href='http://www.vinmeeninfotech.com/' target='_blank' rel='noopener noreferrer'>
						VinMeen Infotech
					</a>
					{/* <Heart className="text-danger" size={15} /> */}
				</span>
			</p>
			{props.hideScrollToTop === false ? (
				<ScrollToTop showUnder={160}>
					<Button color='warning' className='btn-icon scroll-top'>
						<ArrowUp size={15} />
					</Button>
				</ScrollToTop>
			) : null}
		</footer>
	)
}

export default Footer
