import axios from 'axios'
export function ERRORLOG(user, version, class_name, function_name, line_no, error) {
	try {
		axios({
			method: 'Post',
			url: global.config.geturl.ipaddress.usersUrl + '/errorwriting/',
			params: {
				typecode: 1,
			},
			data: {
				usercode: user,
				version: version,
				classname: class_name,
				functionname: function_name,
				lineno: line_no,
				error: error,
			},
		}).then(response => {
			// console.log(response.data)
			// console.log(function_name)
			// console.log(error)
			// console.log(error)
			// console.log(error)
			var result = response.data.error_json_result.varstatuscode
			if (result === 20) {
				console.log(response.data.error_json_result.responsestring)
			} else {
				console.log(response.data.error_json_result.responsestring)
			}
		})
	} catch (error) {
		// console.log(error)
	}
}
