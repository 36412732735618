import mock from '../mock'
const data = [
	{
		state: 'Tamilnadu',
		status: 'Active',
		districts_count: 10,
		counter: 3,
		id: 1,
	},
	{
		state: 'Delhi',
		status: 'Active',
		districts_count: 10,
		counter: 2,
		id: 2,
	},
	{
		state: 'Mumbai',
		status: 'Inactive',
		districts_count: 10,
		counter: 3,
		id: 3,
	},
]
mock.onGet('/api/state/data').reply(200, {
	data,
})
