module.exports = global.config = {
	geturl: {
		ipaddress: {
			//  url: "https://apitest.jobanya.com/api/controller/"// TEST
			// url: "https://uatapi.jobanya.com/api/controller/" //UAT
			// url: "https://apilive.jobanya.com/api/controller/" //Live
			url: 'https://apilive.jobanya.com/api/controller/', //dev
			usersUrl: 'https://testapi.jobanya.com/services/users/',
			authUrl: 'https://testapi.jobanya.com/services/auth/',
			adminUserUrl: 'https://testapi.jobanya.com/services/admin-user/',
			communicationUrl: 'https://testapi.jobanya.com/services/communication/',
			filesUrl: 'https://testapi.jobanya.com/services/files/',
			jobstreamUrl: 'https://testapi.jobanya.com/services/jobstream/',
			profileUrl: 'https://testapi.jobanya.com/services/profile/',
			reportUrl: 'https://testapi.jobanya.com/services/reports/',
		},
		// rest of your translation object
	},
	// other global config variables you wish
}
