import mock from '../mock'
const data = [
	{
		educationalcateogry: 'UG,PG',
		specialization: 'Computer Science',
		status: 'Inactive',
		languagecount: '1',

		action: 'Edit',
	},
	{
		educationalcateogry: 'UG,PG',
		specialization: 'Maths',
		status: 'Active',
		languagecount: '3',

		action: 'Delete',
	},
	{
		educationalcateogry: 'PG',
		specialization: 'Commerce',
		status: 'Inactive',
		languagecount: '0',

		action: 'Edit',
	},
]
mock.onGet('/api/specialization/data').reply(200, {
	data,
})
