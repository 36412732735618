import mock from '../mock'
const data = [
	{
		packagename: 'Teacher',
		packagevalidity: '10',
		price: '10000',
		jobpostsallowed: '10',
		jobvacanciesallowed: '10',
		jobvalidity: '12',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		plantype: 'Custom',
		counter: '3',
		status: 'Inactive',

		action: 'Edit',
	},
	{
		packagename: 'Developer',
		packagevalidity: '10',
		price: '20000',
		jobpostsallowed: '6',
		jobvacanciesallowed: '8',
		jobvalidity: '10',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		counter: '3',
		status: 'Active',
		plantype: 'Regular',
		action: 'Delete',
	},
	{
		packagename: 'Manager',
		packagevalidity: '12',
		price: '25000',
		jobpostsallowed: '8',
		jobvacanciesallowed: '5',
		jobvalidity: '5',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		counter: '3',
		status: 'Inactive',
		plantype: 'Custom',
		action: 'Edit',
	},
	{
		packagename: 'Head Master',
		packagevalidity: '8',
		price: '25000',
		jobpostsallowed: '10',
		jobvacanciesallowed: '10',
		jobvalidity: '15',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		counter: '2',
		status: 'Active',
		plantype: 'Custom',
		action: 'Delete',
	},
	{
		packagename: 'Officer',
		packagevalidity: '10',
		price: '15000',
		jobpostsallowed: '4',
		jobvacanciesallowed: '7',
		jobvalidity: '20',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		counter: '3',
		status: 'Active',
		plantype: 'Custom',
		action: 'Delete',
	},
	{
		packagename: 'Nurse',
		packagevalidity: '7',
		price: '11000',
		jobpostsallowed: '20',
		jobvacanciesallowed: '10',
		jobvalidity: '25',
		profileview: '10',
		profileshortlist: '12',
		jobapply: '10',
		candidatesinvite: '10',
		counter: '1',
		status: 'Inactive',
		plantype: 'Regular',
		action: 'Edit',
	},
]
mock.onGet('/api/jobpackage/data').reply(200, {
	data,
})
