import mock from './mock'
import './apps/dataView'
import './tables/aggrid'
import './tables/designation_data'
import './tables/jobfunction_data'
import './tables/jobrole_data'
import './tables/state_data'
import './tables/city_data'
import './tables/specialization_data'
import './tables/jobgroup_data'
import './tables/eduqualification_data'
import './tables/governmentorganization_data'
import './tables/qualification_mapping'
import './tables/specialization_mapping'
import './tables/news_data'
import './tables/governmentorganization_data'
import './tables/industrytype_data'
import './tables/usermaster_data'
import './tables/jobpackage_data'
import './tables/skill_data'
import './tables/district_data'
import './tables/governmentsjobs_data'
import './autoComplete/autoComplete'
import './navbar/navbarSearch'
import './navbar/navbarBookmarkSearch'

import './apps/email'
import './apps/chat'
import './apps/todo'
import './apps/calendar'
import './apps/userList'
import './tables/employer_approval_data'
import './tables/jobapproval_data'
import './tables/abuse_management_data'
import './tables/sms_template_data'
import './tables/employer_management_data'
import './tables/subscription_data'
import './tables/jobpostlist_data'
import './tables/appliedlist_data'
import './tables/invitedlist_data'
import './tables/shortlistedlist_data'
import './tables/wishlist_data'
import './tables/facility_data'
import './tables/employee_list'
import './tables/employee_applied_data.js'
import './tables/employee_invited_data.js'
import './tables/employee_wishlist_data.js'
import './tables/splashscreen_data'
import './tables/userrole'

import './tables/citypost_data'

mock.onAny().passThrough()
