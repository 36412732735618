import mock from '../mock'
const data = [
	{
		employee_name: 'Guna',
		qualification: 'B.E',
		city: 'Virudhunagar',
		district: 'Virudhunagar',
		mobile: '9489290997',
		email: 'emp1@Jobanya.com',
		status: 'Verified',
		logo: require('../../assets/img/portrait/small/avatar-s-9.jpg'),
	},
	{
		employee_name: 'Gokul',
		qualification: 'B.tech',
		city: 'Rajapalayam',
		district: 'Virudhunagar',
		mobile: '9498290997',
		email: 'emp2@Jobanya.com',
		status: 'Blocked',
		logo: require('../../assets/img/portrait/small/avatar-s-1.jpg'),
	},
	{
		employee_name: 'Gokula priya',
		qualification: 'B.Sc',
		city: 'Sattur',
		district: 'Virudhunagar',
		mobile: '9498296797',
		email: 'emp3@Jobanya.com',
		status: 'Deactivated',
		logo: require('../../assets/img/portrait/small/avatar-s-6.jpg'),
	},
	{
		employee_name: 'Priya',
		qualification: 'M.Sc',
		city: 'Rajapalayam',
		district: 'Virudhunagar',
		mobile: '9492420997',
		email: 'emp4@Jobanya.com',
		status: 'Blocked',
		logo: require('../../assets/img/portrait/small/avatar-s-2.jpg'),
	},
	{
		employee_name: 'Samantha',
		qualification: 'B.tech',
		city: 'Sattur',
		district: 'Virudhunagar',
		mobile: '9412221997',
		email: 'emp5@Jobanya.com',
		status: 'Verified',
		logo: require('../../assets/img/portrait/small/avatar-s-4.jpg'),
	},
]
mock.onGet('/api/employee_list/data').reply(200, {
	data,
})
