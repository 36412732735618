import mock from '../mock'
const data = [
	{
		degree: 'UG',
		eduqualification: 'B.sc',
		status: 'Inactive',
		action: 'Edit',
	},
	{
		degree: 'PG',
		eduqualification: 'M.sc',
		status: 'Active',
		action: 'Delete',
	},
	{
		degree: 'UG',
		eduqualification: 'B.C.A',
		status: 'Inactive',
		action: 'Edit',
	},
	{
		degree: 'PG',
		eduqualification: 'M.C.A',
		status: 'Active',
		action: 'Delete',
	},
	{
		degree: 'UG',
		eduqualification: 'B.B.A',
		status: 'Active',
		action: 'Delete',
	},
	{
		degree: 'UG',
		eduqualification: 'B.A',
		status: 'Inactive',
		action: 'Edit',
	},
]
mock.onGet('/api/eduqualification/data').reply(200, {
	data,
})
