import mock from '../mock'
const data = [
	{
		jobpostid: 'JB001',
		employer_name: 'SSS',
		jobfunction: 'Driver',
		jobrole: 'Car Driver',
		posteddate: '02/03/2020',
		lastdate: '12/07/2020',
		status: 'Pending',
		logo: require('../../assets/img/portrait/small/company2.jpeg'),
	},
	{
		jobpostid: 'JB002',
		employer_name: 'Diamond Ltd',
		jobfunction: 'Developer',
		jobrole: 'Android Developer',
		posteddate: '02/03/2020',
		lastdate: '10/05/2020',
		status: 'Approved',
		logo: require('../../assets/img/portrait/small/company1.jpg'),
	},
	{
		jobpostid: 'JB003',
		employer_name: 'KIA',
		jobfunction: 'Manager',
		jobrole: 'Accounts Manager',
		posteddate: '02/03/2020',
		lastdate: '08/03/2020',
		status: 'Approved',
		logo: require('../../assets/img/portrait/small/company4.jpg'),
	},
	{
		jobpostid: 'JB004',
		employer_name: 'Google',
		jobfunction: 'Teacher',
		jobrole: 'Sports Teacher',
		posteddate: '01/03/2020',
		lastdate: '02/03/2020',
		status: 'Pending',
		logo: require('../../assets/img/portrait/small/company3.jpg'),
	},
	{
		jobpostid: 'JB005',
		employer_name: 'A-Z',
		jobfunction: 'Driver',
		jobrole: 'Lorry Driver',
		posteddate: '02/03/2020',
		lastdate: '02/12/2020',
		status: 'Pending',
		logo: require('../../assets/img/portrait/small/company1.jpg'),
	},
]
mock.onGet('/api/jobapproval/data').reply(200, {
	data,
})
