import mock from '../mock'
const data = [
	{
		userrole: 'Officer',
		status: 'Inactive',
	},
	{
		userrole: 'Managing Director',
		status: 'Active',
	},
	{
		userrole: 'Accounts Officer',
		status: 'Active',
	},
	{
		userrole: 'Manager',
		status: 'Inactive',
	},
	{
		userrole: 'HR',
		status: 'Active',
	},
]
mock.onGet('/api/userrole/data').reply(200, {
	data,
})
