import mock from '../mock'
const data = [
	{
		jobfunction: 'Quality Analyzer',
		status: 'Active',
		action: 'Edit',
		no_of_jobroles: 3,
		counter: 3,
	},
	{
		jobfunction: 'Developer',
		status: 'Inactive',
		action: 'Delete',
		no_of_jobroles: 3,
		counter: 3,
	},
	{
		jobfunction: 'Sales',
		status: 'Active',
		action: 'Edit',
		no_of_jobroles: 10,
		counter: 2,
	},
]
mock.onGet('/api/jobfunction/data').reply(200, {
	data,
})
