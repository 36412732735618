import React, {PureComponent} from 'react'
import classnames from 'classnames'
import Customizer from '../components/@vuexy/customizer/Customizer'
import Sidebar from './components/menu/vertical-menu/Sidebar'
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import {history} from '../history'
import {connect} from 'react-redux'
import IdleTimer from 'react-idle-timer'
import {
	changeMode,
	collapseSidebar,
	changeNavbarColor,
	changeNavbarType,
	changeFooterType,
	changeMenuColor,
	hideScrollToTop,
} from '../redux/actions/customizer/index'
let error_log = require('../error_log')
class VerticalLayout extends PureComponent {
	idleTimer = null
	onAction = this._onAction.bind(this)
	onActive = this._onActive.bind(this)
	onIdle = this._onIdle.bind(this)
	state = {
		timeout: 1000 * 180 * 10,
		isTimedOut: false,
		width: window.innerWidth,
		sidebarState: this.props.app.customizer.sidebarCollapsed,
		layout: this.props.app.customizer.theme,
		collapsedContent: this.props.app.customizer.sidebarCollapsed,
		sidebarHidden: false,
		currentLang: 'en',
		appOverlay: false,
		customizer: false,
		currRoute: this.props.location.pathname,
		version: global.commonvariable.getcommon.variable.version,
		usercode: 0,
	}
	_onAction(e) {
		// console.log('user did something', e)
		this.setState({isTimedOut: false})
	}

	_onActive(e) {
		// console.log('user is active', e)
		this.setState({isTimedOut: false})
	}

	_onIdle(e) {
		// console.log('user is idle', e)
		const isTimedOut = this.state.isTimedOut
		if (isTimedOut) {
			// this.props.history.push('/')
			this.logout()
		} else {
			this.setState({showModal: true})
			this.idleTimer.reset()
			this.setState({isTimedOut: true})
		}
	}
	logout() {
		localStorage.setItem('usercode', '')
		localStorage.setItem('userrolename', '')
		localStorage.setItem('designationname', '')
		localStorage.setItem('statuscode', '')
		localStorage.setItem('userrolecode', '')
		localStorage.setItem('designationcode', '')
		localStorage.clear()
		history.push('/')
	}
	collapsedPaths = []
	mounted = false
	updateWidth = () => {
		try {
			if (this.mounted) {
				this.setState(prevState => ({
					width: window.innerWidth,
				}))
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'updateWidth', 0, error.toString())
		}
	}

	handleCustomizer = bool => {
		try {
			this.setState({
				customizer: bool,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleCustomizer', 0, error.toString())
		}
	}

	componentDidMount() {
		try {
			if (localStorage.getItem('usercode') === '') {
				localStorage.clear()
				history.push('/')
				return
			}

			this.mounted = true
			let {
				location: {pathname},
				app: {
					customizer: {theme, direction},
				},
			} = this.props

			if (this.mounted) {
				if (window !== 'undefined') {
					window.addEventListener('resize', this.updateWidth, false)
				}
				if (this.collapsedPaths.includes(pathname)) {
					this.props.collapseSidebar(true)
				}

				let layout = theme
				let dir = direction
				if (dir === 'rtl') document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
				else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
				return layout === 'dark'
					? document.body.classList.add('dark-layout')
					: layout === 'semi-dark'
						? document.body.classList.add('semi-dark-layout')
						: null
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'componentDidMount', 0, error.toString())
		}
	}

	componentDidUpdate(prevProps, prevState) {
		try {
			let {
				location: {pathname},
				app: {
					customizer: {theme, sidebarCollapsed},
				},
			} = this.props

			let layout = theme
			if (this.mounted) {
				if (layout === 'dark') {
					document.body.classList.remove('semi-dark-layout')
					document.body.classList.add('dark-layout')
				}
				if (layout === 'semi-dark') {
					document.body.classList.remove('dark-layout')
					document.body.classList.add('semi-dark-layout')
				}
				if (layout !== 'dark' && layout !== 'semi-dark') {
					document.body.classList.remove('dark-layout', 'semi-dark-layout')
				}

				if (prevProps.app.customizer.sidebarCollapsed !== this.props.app.customizer.sidebarCollapsed) {
					this.setState({
						collapsedContent: sidebarCollapsed,
						sidebarState: sidebarCollapsed,
					})
				}
				if (
					prevProps.app.customizer.sidebarCollapsed === this.props.app.customizer.sidebarCollapsed &&
					pathname !== prevProps.location.pathname &&
					this.collapsedPaths.includes(pathname)
				) {
					this.props.collapseSidebar(true)
				}
				if (
					prevProps.app.customizer.sidebarCollapsed === this.props.app.customizer.sidebarCollapsed &&
					pathname !== prevProps.location.pathname &&
					!this.collapsedPaths.includes(pathname)
				) {
					this.props.collapseSidebar(false)
				}
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'componentDidUpdate', 0, error.toString())
		}
	}

	handleCollapsedMenuPaths = item => {
		try {
			let collapsedPaths = this.collapsedPaths
			if (!collapsedPaths.includes(item)) {
				collapsedPaths.push(item)
				this.collapsedPaths = collapsedPaths
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleCollapsedMenuPaths', 0, error.toString())
		}
	}

	toggleSidebarMenu = val => {
		try {
			this.setState({
				sidebarState: !this.state.sidebarState,
				collapsedContent: !this.state.collapsedContent,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'toggleSidebarMenu', 0, error.toString())
		}
	}

	sidebarMenuHover = val => {
		try {
			this.setState({
				sidebarState: val,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'sidebarMenuHover', 0, error.toString())
		}
	}

	handleSidebarVisibility = () => {
		try {
			if (this.mounted) {
				if (window !== undefined) {
					window.addEventListener('resize', () => {
						if (this.state.sidebarHidden) {
							this.setState({
								sidebarHidden: !this.state.sidebarHidden,
							})
						}
					})
				}
				this.setState({
					sidebarHidden: !this.state.sidebarHidden,
				})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleSidebarVisibility', 0, error.toString())
		}
	}

	componentWillUnmount() {
		try {
			this.mounted = false
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'componentWillUnmount', 0, error.toString())
		}
	}

	handleCurrentLanguage = lang => {
		try {
			this.setState({
				currentLang: lang,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleCurrentLanguage', 0, error.toString())
		}
	}

	handleAppOverlay = value => {
		try {
			if (value.length > 0) {
				this.setState({
					appOverlay: true,
				})
			} else if (value.length < 0 || value === '') {
				this.setState({
					appOverlay: false,
				})
			}
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleAppOverlay', 0, error.toString())
		}
	}

	handleAppOverlayClick = () => {
		try {
			this.setState({
				appOverlay: false,
			})
		} catch (error) {
			error_log.ERRORLOG(this.state.usercode, this.state.version, 'VerticalLayout', 'handleAppOverlayClick', 0, error.toString())
		}
	}

	render() {
		let appProps = this.props.app.customizer
		let menuThemeArr = ['primary', 'success', 'danger', 'info', 'warning', 'dark', 'mycolor']
		let sidebarProps = {
			toggleSidebarMenu: this.props.collapseSidebar,
			toggle: this.toggleSidebarMenu,
			sidebarState: this.state.sidebarState,
			sidebarHover: this.sidebarMenuHover,
			sidebarVisibility: this.handleSidebarVisibility,
			visibilityState: this.state.sidebarHidden,
			activePath: this.props.match.path,
			collapsedMenuPaths: this.handleCollapsedMenuPaths,
			currentLang: this.state.currentLang,
			activeTheme: appProps.menuTheme,
			collapsed: this.state.collapsedContent,
			permission: this.props.permission,
			deviceWidth: this.state.width,
		}
		let navbarProps = {
			toggleSidebarMenu: this.toggleSidebarMenu,
			sidebarState: this.state.sidebarState,
			sidebarVisibility: this.handleSidebarVisibility,
			currentLang: this.state.currentLang,
			changeCurrentLang: this.handleCurrentLanguage,
			handleAppOverlay: this.handleAppOverlay,
			appOverlayState: this.state.appOverlay,
			navbarColor: appProps.navbarColor,
			navbarType: appProps.navbarType,
		}

		let footerProps = {
			footerType: appProps.footerType,
			hideScrollToTop: appProps.hideScrollToTop,
		}

		let customizerProps = {
			customizerState: this.state.customizer,
			handleCustomizer: this.handleCustomizer,
			changeMode: this.props.changeMode,
			changeNavbar: this.props.changeNavbarColor,
			changeNavbarType: this.props.changeNavbarType,
			changeFooterType: this.props.changeFooterType,
			changeMenuTheme: this.props.changeMenuColor,
			collapseSidebar: this.props.collapseSidebar,
			hideScrollToTop: this.props.hideScrollToTop,
			activeMode: appProps.theme,
			activeNavbar: appProps.navbarColor,
			navbarType: appProps.navbarType,
			footerType: appProps.footerType,
			menuTheme: appProps.menuTheme,
			scrollToTop: appProps.hideScrollToTop,
			sidebarState: appProps.sidebarCollapsed,
		}
		return (
			<div
				className={classnames(`wrapper vertical-layout theme-${appProps.menuTheme}`, {
					'menu-collapsed': this.state.collapsedContent === true && this.state.width >= 1200,
					'fixed-footer': appProps.footerType === 'sticky',
					'navbar-static': appProps.navbarType === 'static',
					'navbar-sticky': appProps.navbarType === 'sticky',
					'navbar-floating': appProps.navbarType === 'floating',
					'navbar-hidden': appProps.navbarType === 'hidden',
					'theme-primary': !menuThemeArr.includes(appProps.menuTheme),
				})}
			>
				<IdleTimer
					ref={ref => {
						this.idleTimer = ref
					}}
					element={document}
					onActive={this.onActive}
					onIdle={this.onIdle}
					onAction={this.onAction}
					debounce={250}
					timeout={this.state.timeout}
				/>
				<Sidebar {...sidebarProps} />
				<div
					className={classnames('app-content content', {
						'show-overlay': this.state.appOverlay === true,
					})}
					onClick={this.handleAppOverlayClick}
				>
					<Navbar {...navbarProps} />
					<div className='content-wrapper'>{this.props.children}</div>
				</div>

				<Footer {...footerProps} />
				{appProps.disableCustomizer !== true ? <Customizer {...customizerProps} /> : null}
				<div className='sidenav-overlay' onClick={this.handleSidebarVisibility} />
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		app: state.customizer,
	}
}
export default connect(mapStateToProps, {
	changeMode,
	collapseSidebar,
	changeNavbarColor,
	changeNavbarType,
	changeFooterType,
	changeMenuColor,
	hideScrollToTop,
})(VerticalLayout)
