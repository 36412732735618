import mock from '../mock'
const data = [
	{
		industrytype: 'Garments',
		counter: 2,
		status: 'Inactive',
		logo: require('../../assets/img/portrait/small/garments.jpg'),
	},
	{
		industrytype: 'Food exports',
		counter: 3,
		status: 'Active',
		logo: require('../../assets/img/portrait/small/foodexport.png'),
	},
	{
		industrytype: 'Tiles & Granites',
		counter: 3,
		status: 'Inactive',
		logo: require('../../assets/img/portrait/small/tiles.png'),
	},
]
mock.onGet('/api/industrytype/data').reply(200, {
	data,
})
