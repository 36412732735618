import mock from '../mock'
const data = [
	{
		reportedonid: '1',
		reportedon: 'Job Posts',
		reportedby: 'Employee',
		particulars: 'Raj ,Virudhunagar',
		reporteddate: '03/07/2020',
		dateofactiontaken: '05/07/2020',
		status: 'Pending',
		action: 'view',
	},
	{
		reportedonid: '2',
		reportedon: 'Employee',
		reportedby: 'Employer',
		particulars: 'Developer Chennai',
		reporteddate: '06/07/2020',
		dateofactiontaken: '08/07/2020',
		status: 'Pending',
		action: 'view',
	},
]
mock.onGet('/api/abuse_management/data').reply(200, {
	data,
})
